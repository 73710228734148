/* formatPower.js */
function formatPower(power) {
  if (power >= 1000000) {
    const value = (Math.round(power / 100000) / 10).toFixed(1)
    return value.endsWith('.0') ? value.slice(0, -2) + 'M' : value + 'M'
  }
  if (power >= 1000) {
    const value = (Math.round(power / 100) / 10).toFixed(1)
    return value.endsWith('.0') ? value.slice(0, -2) + 'k' : value + 'k'
  }
  return power.toString()
}

export default formatPower
