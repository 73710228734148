import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ExternalRedirect from './components/ExternalRedirect'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsOfService from './pages/TermsOfService'
import TeamMap from './pages/Map/TeamMap'
import Home from './pages/Home/Home'
import './styles/Global.css'
import ScrollToTop from './components/ScrollToTop'
import { TransitionProvider } from './contexts/TransitionContext'
import Layout from './layout/Layout'

function App() {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <TransitionProvider>
        <Layout>
          <Routes>
            {/* APP */}
            <Route path="/" element={<Home />} />
            <Route path="/map/:guildId/:userId?" element={<TeamMap />} />

            {/* LEGAL */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />

            {/* REDIRECTS */}
            <Route
              path="/discord"
              element={
                <ExternalRedirect
                  url="https://discord.gg/QUdZQFyATF"
                  message="Join the MapWarrior support server!"
                />
              }
            />
            <Route
              path="/invite"
              element={
                <ExternalRedirect
                  url="https://discord.com/api/oauth2/authorize?client_id=1145623197006446663&permissions=2147707968&scope=bot%20applications.commands"
                  message="Invite MapWarrior to your server!"
                />
              }
            />
          </Routes>
        </Layout>
      </TransitionProvider>
    </BrowserRouter>
  )
}

export default App
