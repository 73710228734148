import Header from '../components/Header'
import AppLink from '../components/AppLink'
import './PrivacyPolicy.css'

export default function PrivacyPolicy() {
  return (
    <div className="legal-styles-wrapper">
      <div className="legal-styles">
        <header>
          <Header theme="light" />
        </header>
        <main>
          <h1>Privacy Policy</h1>

          <h3>Last updated: September 9, 2023</h3>

          <h2>Introduction</h2>

          <p>
            Welcome to MapWarrior! We value the privacy of our users. This
            Privacy Policy describes how we collect, use, and handle your
            personal data when you use our services.
          </p>

          <h2>Data We Collect</h2>

          <ul>
            <li>
              <b>Account Information:</b> When you interact with our services,
              we may store your username, user ID, and any other information you
              choose to provide.
            </li>
            <li>
              <b>Location Data:</b> MapWarrior uses location data to help users
              locate each other on a map. This data is voluntarily provided by
              users and can be updated or removed at any time.
            </li>
            <li>
              <b>Bot Interactions:</b> Interactions with the MapWarrior bot,
              including commands and other inputs, are processed to provide
              relevant functionality but are not stored beyond necessary
              processing times.
            </li>
          </ul>

          <h2>How We Use Your Data</h2>

          <ul>
            <li>
              <b>To Enhance Our Services:</b> Your data helps us improve and
              personalize your experience.
            </li>
            <li>
              <b>Communication:</b> We might use your information to communicate
              about updates, security, and other service-related issues.
            </li>
          </ul>

          <h2>Data Sharing and Disclosure</h2>

          <p>
            We don&apos;t sell your personal data to advertisers or other third
            parties.
            <br />
            We might share your data:
          </p>

          <ul>
            <li>
              <b>With your consent</b>, for example, when you agree to our
              sharing your information with other services. We use{' '}
              <a
                href="https://openstreetmap.org/copyright"
                target="_blank"
                rel="noreferrer"
              >
                OpenStreetMap
              </a>{' '}
              to lookup coordinates based on user-provided locations in order to
              display them on the map.
            </li>
            <li>
              <b>For legal reasons</b>, if we believe that disclosing
              information is necessary for compliance with a legal obligation.
            </li>
          </ul>

          <h2>Data Retention</h2>

          <p>
            We keep your data only as long as we need it for legitimate business
            purposes and as permitted by applicable legal requirements.
          </p>

          <h2>Your Rights</h2>

          <p>
            You can request access, correction, or deletion of your personal
            data at any time. Additionally, you are free to deactivate your
            MapWarrior account.
          </p>

          <h2>Security</h2>

          <p>
            We work hard to protect your data and employ security measures to
            ensure the integrity and confidentiality of all information we
            collect.
          </p>

          <h2>Updates to this Policy</h2>

          <p>
            From time to time, we may update this Privacy Policy. We will notify
            users of any changes and encourage you to review this policy
            regularly.
          </p>

          <h2>Contact Us</h2>

          {/* To Do - setup mapwarrior.gg email */}
          <p>
            For any questions about this Privacy Policy or our data practices,
            please contact us at{' '}
            <a href="mailto:info@hashtagmagic.io?subject=MapWarrior">
              info@hashtagmagic.io
            </a>
            .
          </p>
        </main>
        <footer>
          <div className="links">
            <AppLink to="/">Home</AppLink>
            <AppLink to="/privacy-policy">Privacy Policy</AppLink>
            <AppLink to="/terms-of-service">Terms of Service</AppLink>
          </div>
        </footer>
      </div>
    </div>
  )
}
