import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled/macro'
import { FaSkullCrossbones } from 'react-icons/fa6'
import useUrlParams from '../../hooks/useUrlParams'
import MapHeader from '../Map/components/MapHeader'
import MapFooter from '../Map/components/MapFooter'
import StyledMap from './styled/StyledMap'
import StyledContent from './styled/StyledContent'
import MapHero from './MapHero'
import HomeContentMain from './HomeContentMain'
import HomeFooter from './HomeFooter'

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  .overscroll {
    /* margin-bottom: -100px; */
    position: fixed;
    background-color: var(--cream);
    /* transform: translateY(100px); */
    /* bottom: -100px; */
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    padding-bottom: 20px;
    z-index: -1;
    font-size: 2rem;
    color: var(--pink);
    h6 {
      font-family: 'Cinzel', sans-serif;
      font-weight: 600;
      font-size: 1rem;
      color: var(--background);
    }
  }
`

function Home() {
  const theme = 'dark'

  const { guildId, userId } = useUrlParams(useParams())

  useEffect(() => {
    if (guildId && userId) {
      window.location.href = `/map/${guildId}/${userId}`
    }
  }, [guildId, userId])

  return (
    <StyledContainer>
      <StyledMap>
        <MapHeader theme={theme} />

        <MapHero />

        <a
          href="https://discord.com/api/oauth2/authorize?client_id=1145623197006446663&permissions=2147707968&scope=bot%20applications.commands"
          target="_blank"
          rel="noreferrer"
          style={{ pointerEvents: 'auto' }}
        >
          <MapFooter
            name="Invite Now"
            iconUrl="https://mapwarrior.gg/logo.png"
            theme={theme}
            size="large"
          />
        </a>
      </StyledMap>
      <StyledContent>
        <HomeContentMain />
      </StyledContent>
      <HomeFooter />
      <div className="overscroll">
        <FaSkullCrossbones />
        <h6>Beware</h6>
      </div>
    </StyledContainer>
  )
}

export default Home
