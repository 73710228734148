import React from 'react'
import './Invalid.css'

function Invalid() {
  return (
    <div className="invalid">
      <div className="invalidText">
        This is not the map you're loooking for :)
      </div>
    </div>
  )
}

export default Invalid
