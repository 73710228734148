import React, { useMemo } from 'react'
import { ReactComponent as ShieldIcon } from '../../assets/shield-icon.svg'
import AppLink from '../../components/AppLink'
import StyledFooter from './styled/StyledFooter'

function HomeFooter() {
  const currentYear = useMemo(() => new Date().getFullYear(), [])
  return (
    <StyledFooter>
      <section>
        <div className="middle">
          <AppLink to="/">
            <h6>MapWarrior</h6>
            <ShieldIcon className="shield-icon" />
          </AppLink>
        </div>
      </section>
      <section>
        <div className="copyright">
          Copyright © {currentYear}{' '}
          <a href="https://hashtagmagic.io">#MAGIC</a>
        </div>
        <div className="links">
          <AppLink href="https://discord.com/api/oauth2/authorize?client_id=1145623197006446663&permissions=2147707968&scope=bot%20applications.commands">
            Add Bot
          </AppLink>
          <span>•</span>
          <AppLink href="https://discord.com/invite/QUdZQFyATF">
            Join Server
          </AppLink>
          <span>•</span>
          <AppLink to="/privacy-policy">Privacy Policy</AppLink>
          <span>•</span>
          <AppLink to="/terms-of-service">Terms of Service</AppLink>
        </div>
      </section>
    </StyledFooter>
  )
}

export default HomeFooter
