import React from 'react'
import { Marker, Popup } from 'react-leaflet'
import styled from '@emotion/styled/macro'
import formatPower from '../../lib/formatPower'
import getLocalTime from '../../lib/getLocalTime'
import createIcon from './lib/createIcon'
import './MapMarker.css' // only for createIcon

const StyledPopup = styled(Popup)`
  .leaflet-popup-content-wrapper {
    border-radius: 100px;
    background-color: ${(props) =>
      props.status === 'online' ? 'var(--green)' : 'var(--medium-blue)'};
    border: 1px solid var(--white);
    .leaflet-popup-content {
      margin: 4px 30px;
    }
  }
  .leaflet-popup-tip-container {
    display: none; /* Hide the default popup tip arrow. If you want to keep and color it, then don't use this rule. */
  }
`

const StyledMapMarker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  font-weight: 700;
  white-space: nowrap;
  color: var(--white);
  .username {
    font-size: 13px;
  }
  .power {
    padding: 2px 0;
    font-family: 'Press Start 2P', cursive;
    font-size: 0.9rem;
    color: var(--cream);
  }
  .time {
    font-size: 11px;
    color: var(--border);
    font-family: 'courier new', courier, monospace;
  }
`

const offsetAmount = 0.0001
const offsetCoords = (coords, i) => {
  return [coords[0] + offsetAmount * i, coords[1] + offsetAmount * i]
}

function MapMarker({
  i,
  theme,
  avatarUrl,
  location,
  position,
  power,
  status,
  username,
}) {
  // const map = useMap();

  const offsetPosition = offsetCoords(position, i)

  // const handleMarkerClick = () => {
  //   map.flyTo(offsetPosition);
  // };

  const localTime = getLocalTime(position[0], position[1])

  return (
    <Marker
      position={offsetPosition}
      icon={createIcon({ i, theme, avatarUrl, position, status, username })}
      // eventHandlers={{ click: handleMarkerClick }}
    >
      <StyledPopup
        offset={[0, 113]}
        closeButton={false}
        autoPanPadding={[50, 50]}
        status={status}
      >
        <StyledMapMarker>
          <div className="username">
            #{i + 1} {username}
          </div>
          <div className="power">{formatPower(power)}</div>
          <div className="time">
            {localTime} in {location}
          </div>
        </StyledMapMarker>
      </StyledPopup>
    </Marker>
  )
}

export default MapMarker
