import { useState, useEffect } from 'react'

const DEBUG = process.env.DEBUG || false

const useDefaultZoom = () => {
  const [zoom, setZoom] = useState(null)

  useEffect(() => {
    const handleResize = () => {
      const isLandscape = window.innerWidth / window.innerHeight > 1.1 // adjust this value as needed
      if (DEBUG)
        console.log('aspectRation', window.innerWidth / window.innerHeight)
      if (DEBUG) console.log('w', window.innerWidth, 'h', window.innerHeight)
      const hasTouch = window.matchMedia('(pointer: coarse)').matches

      if (hasTouch && isLandscape) {
        setZoom(1)
      } else if (window.innerHeight > 1000) {
        setZoom(3)
      } else {
        setZoom(2)
      }
    }

    // Call once for initial setup
    handleResize()

    // Attach event listener
    window.addEventListener('resize', handleResize)

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (DEBUG) console.log('zoom', zoom)
  return zoom
}

export default useDefaultZoom
