import styled from '@emotion/styled/macro'

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 15px 200px;
  margin: -50px 0 0;
  min-height: 100%;
  box-shadow: 0px -5px 5px var(--dark-blue);
  background-color: var(--pink);
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  background-image: repeating-radial-gradient(
    circle closest-side at center,
    #ffffff33 0%,
    #133d7107 33%,
    #ffffff33 33%,
    #133d7107 66%,
    #ffffff33 66%,
    #133d7107 99%
  );
  @media (min-width: 768px) {
    padding: 20px 40px 200px;
    background-image: repeating-radial-gradient(
      circle farthest-corner at top left,
      #ffffff33 0%,
      #133d7107 10%,
      #ffffff33 10%,
      #133d7107 20%,
      #ffffff33 20%,
      #133d7107 30%
    );
  }
`

export default StyledContent
