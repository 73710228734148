import React from 'react'
import { formatDistanceToNow } from 'date-fns'
import { FaCrown, FaShieldAlt, FaStar, FaTrophy } from 'react-icons/fa'
import styled from '@emotion/styled/macro'
import { keyframes } from '@emotion/react/macro'
import { BORDER } from '../../../styles/Colors'
import formatPower from '../../../lib/formatPower'
import { iconToDataURI } from '../lib/createIcon'

const fadeInWindow = keyframes`
  0% {
    transform: translateX(100vw);
  }
  40% {
    transform: translateX(-2%);
  }
  60% {
    transform: translateX(1%);
  }
  100% {
    transform: translateX(0);
  }
`

const StyledLeaderboardItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 10px;
  min-height: 30px;
  border-bottom: 1px solid var(--box-shadow-heavy);
  background-color: ${({ isMe }) =>
    isMe ? 'var(--box-shadow-heavy)' : 'none'};
  transform: translateX(100vw);
  animation-delay: ${(props) => `${props.i * 0.1 + 0.1}s`};
  animation: ${fadeInWindow} 0.6s forwards ease-in-out;
  &:last-of-type {
    border-bottom: none;
  }
  &:nth-of-type(1) .name-text > span {
    background: linear-gradient(to bottom, #fff, #ffd700);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  &:nth-of-type(2) .name-text > span {
    background: linear-gradient(to bottom, #fff, #bbb);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  &:nth-of-type(3) .name-text > span {
    background: linear-gradient(to bottom, #cd7f32, #f3c5a6, #cd7f32);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  .rank {
    min-width: 26px;
    text-align: right;
    color: ${(props) =>
      props.status === 'online' ? 'var(--green)' : 'var(--border)'};
  }
  .name {
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    .name-column {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .name-text {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 10px;
        .leader-icon,
        .vice-leader-icon,
        .trophy-icon {
          font-size: 15px;
          &.gold {
            color: #ffd700;
          }
          &.silver {
            color: #bbb;
          }
          &.bronze {
            color: #cd7f32;
          }
        }
        .leader-icon {
          color: var(--sun-color);
        }
        .vice-leader-icon {
          color: var(--star-color);
        }
      }
      .last-activity {
        font-size: 10px;
        font-family: 'courier new', courier, monospace;
        font-weight: 600;
        color: var(--border);
      }
    }
    .image,
    .icon {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      &::before {
        content: '';
        display: ${(props) => (props.status === 'online' ? 'block' : 'none')};
        position: absolute;
        top: -4px;
        right: -4px;
        width: 14px;
        height: 14px;
        background-color: var(--green);
        border-radius: 50%;
        border: 2px solid var(--dark-blue);
        /* border: 1px solid var(--white); */
        z-index: 1000;
      }
    }
    .image img {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      border: 1px solid var(--white);
      /* box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); */
    }
    .icon img {
      border: none;
      box-shadow: none;
      filter: drop-shadow(0 1px 0 rgba(255, 255, 255, 0.5));
    }
  }
  @media (max-width: 767px) {
    & .name .name-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }
  .score {
    text-align: right;
    color: var(--cream);
  }
`

function formatLastActivityDate(lastActivity) {
  return formatDistanceToNow(new Date(lastActivity), { addSuffix: true })
}

function Avatar({ avatarUrl, username }) {
  return (
    <div className="image">
      <img src={avatarUrl} alt={username} />
    </div>
  )
}

function NoAvatar({ username }) {
  return (
    <div className="icon">
      <img src={iconToDataURI(FaShieldAlt, 30, BORDER)} alt={username} />
    </div>
  )
}

function NameAndAwards({ i, leader, viceLeader, username }) {
  return (
    <div className="name-text">
      <span>{username}</span>
      {i === 0 && <FaTrophy className="trophy-icon gold" />}
      {i === 1 && <FaTrophy className="trophy-icon silver" />}
      {i === 2 && <FaTrophy className="trophy-icon bronze" />}
      {leader && <FaCrown className="leader-icon" />}
      {viceLeader && <FaStar className="vice-leader-icon" />}
    </div>
  )
}

function LastActivity({ lastActivity, status }) {
  if (status !== 'online' && !lastActivity) return null
  if (status === 'online') {
    return <div className="last-activity">now</div>
  }
  return (
    <div className="last-activity">{formatLastActivityDate(lastActivity)}</div>
  )
}

function Score({ power }) {
  return <span className="score">{formatPower(power)}</span>
}

function LeaderboardItem({
  i,
  isMe,
  avatarUrl,
  lastActivity,
  leader,
  power,
  viceLeader,
  status,
  username,
  onUserClick,
}) {
  return (
    <StyledLeaderboardItem
      isMe={isMe}
      status={status}
      i={i}
      onClick={() => onUserClick(i)}
    >
      <span className="rank">{i + 1}</span>
      <span className="name">
        {avatarUrl && <Avatar avatarUrl={avatarUrl} username={username} />}
        {!avatarUrl && <NoAvatar username={username} />}
        <div className="name-column">
          <NameAndAwards
            i={i}
            leader={leader}
            viceLeader={viceLeader}
            username={username}
          />
          <LastActivity lastActivity={lastActivity} status={status} />
        </div>
      </span>
      <Score power={power} />
    </StyledLeaderboardItem>
  )
}

export default LeaderboardItem
