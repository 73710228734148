const getTileUrl = (theme) => {
  const tileURL =
    theme === 'light'
      ? 'https://tile.thunderforest.com/pioneer/{z}/{x}/{y}.png?apikey=589bd09b28e541d181bde761748872e8'
      : 'https://tile.thunderforest.com/transport-dark/{z}/{x}/{y}.png?apikey=589bd09b28e541d181bde761748872e8'
  return tileURL
}

export default getTileUrl

// ## Map Tile Sets
// * "https://tile.openstreetmap.org/{z}/{x}/{y}.png"
// * "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
// * "https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}.png"
// * "https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.png"
// * "https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png"
// * "https://tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=589bd09b28e541d181bde761748872e8"
// * "https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=589bd09b28e541d181bde761748872e8"
// * "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png";
// * "https://tile.thunderforest.com/mobile-atlas/{z}/{x}/{y}.png?apikey=589bd09b28e541d181bde761748872e8"
// * "https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=589bd09b28e541d181bde761748872e8"
// * "https://tile.thunderforest.com/neighbourhood/{z}/{x}/{y}.png?apikey=589bd09b28e541d181bde761748872e8"
// * "https://tile.thunderforest.com/spinal-map/{z}/{x}/{y}.png?apikey=589bd09b28e541d181bde761748872e8"
// * "https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}.png"
