import React from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import {
  FaDiscord,
  FaRegCircleQuestion,
  FaSkullCrossbones,
} from 'react-icons/fa6'
// import { AppLink } from 'react-router-dom'
import AppLink from '../../components/AppLink'
// import { ReactComponent as ShieldIcon } from '../../assets/shield-icon.svg'
import StyledContentMain from './styled/StyledContentMain'
import StyledList from './styled/StyledList'

function HomeContentMain() {
  return (
    <StyledContentMain>
      <section className="heading">
        <h2>MapWarrior is a Discord Bot.</h2>
        <p>Use it to keep track of your teamamates and their scores</p>
        <div className="live-demo">
          <AppLink to="/map/1063236627754917889/907117704996069417?demo=1">
            <FaSkullCrossbones /> Live Demo
          </AppLink>
        </div>
      </section>
      <section className="description">
        <h4>
          <FaInfoCircle className="icon" />
        </h4>
        <p>
          Level up your team with MapWarrior!
          <br />
          <br />
          Unite and collaborate in real-time, mark your location, power, and
          availability, and discover who's active across the globe.
          <br />
          <br />
          Perfect for remote teams seeking synchronous connections.
          <br />
          <br />
          Dive into a world where availability meets adventure. Power up,
          connect, and conquer with MapWarrior!
        </p>
      </section>
      <div className="group">
        <section className="how-to">
          <h4>
            How to Invite the Bot <FaRegCircleQuestion className="icon" />
          </h4>
          <StyledList>
            <li>
              <span />
              <div>
                Click on the{' '}
                <a
                  href="https://discord.com/api/oauth2/authorize?client_id=1145623197006446663&permissions=2147707968&scope=bot%20applications.commands"
                  target="_blank"
                  rel="noreferrer"
                >
                  Invite
                </a>{' '}
                link.
              </div>
            </li>
            <li>
              <span />
              <div>You'll be taken to Discord's authorization page.</div>
            </li>
            <li>
              <span />
              <div>
                Choose the server you wish to add the bot to from the dropdown
                menu.
              </div>
            </li>
            <li>
              <span />
              <div>
                Confirm the requested permissions. Don't worry, we only ask for
                what's necessary to provide the best experience.
              </div>
            </li>
            <li>
              <span />
              <div>
                Click on the &apos;Authorize&apos; button and complete the
                CAPTCHA if prompted.
              </div>
            </li>
            <li>
              <span />
              <div>
                Join our{' '}
                <a
                  href="https://discord.com/invite/QUdZQFyATF"
                  target="_blank"
                  rel="noreferrer"
                >
                  MapWarrior Server
                </a>{' '}
                to get help and support.
              </div>
            </li>
          </StyledList>
        </section>
        <section className="shield">
          <h4>Join our Discord Server</h4>
          <a
            href="https://discord.com/invite/QUdZQFyATF"
            target="_blank"
            rel="noreferrer"
          >
            {/* <img src="/shield.svg" alt="shield" /> */}
            {/* <ShieldIcon className="icon" /> */}
            <img
              src="/mapwarrior-outcrop.png"
              alt="Logo"
              className="img rotate"
            />
          </a>
        </section>
      </div>
      <section className="description commands">
        <h4>Commands</h4>

        <div id="location" className="command">
          <h5>
            <img src="/logo.png" alt="Bot Icon" /> /location
          </h5>
          <p>
            Set your location. This will add you to your server map. Use a
            general location like a city or zip code instead of your exact
            location or address.
            <small>
              {' '}
              Location lookups provided by{' '}
              <a
                href="https://openstreetmap.org/copyright"
                target="_blank"
                rel="noreferrer"
              >
                OpenStreetMap
              </a>
            </small>
          </p>
        </div>
        <div id="power" className="command">
          <h5>
            <img src="/logo.png" alt="Bot Icon" /> /power
          </h5>
          <p>
            Set your power level (or score). Use this command whenever your
            power/score changes to keep your team up to date and move up the
            leaderboard.
          </p>
        </div>
        <div id="map" className="command">
          <h5>
            <img src="/logo.png" alt="Bot Icon" /> /map
          </h5>
          <p>
            View the map using a personalized web link. All server members who
            have joined using the <b>/location</b> command will appear on the
            map. Don't share your map link unless you want others to see it as
            you.
          </p>
        </div>
        <div id="leaderboard" className="command">
          <h5>
            <img src="/logo.png" alt="Bot Icon" /> /leaderboard
          </h5>
          <p>
            View your team&apos;s current leaderboard based on member{' '}
            <b>/power</b> levels. This is the same leaderboard that appears in
            the map if you press the trophy icon.
          </p>
        </div>

        <div className="note">
          <h5>
            <a
              href="https://discord.gg/2WaJZbqFAy"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/wing-fighter.png" alt="Wing Fighter Logo" />
            </a>{' '}
            Wing Fighter Commands
          </h5>
          <p>
            The following commands are currently only useful in the scope of the
            mobile game{' '}
            <a
              href="https://discord.gg/2WaJZbqFAy"
              target="_blank"
              rel="noreferrer"
            >
              <span>Wing Fighter</span>
            </a>{' '}
            but we're looking to extend them to be universally applicable so
            feedback is appreciated.
          </p>
        </div>
        <div id="loot" className="command">
          <h5>
            <img src="/logo.png" alt="Bot Icon" /> /loot
          </h5>
          <p>
            View the 24-hour rolling Loot Leaderboard.{' '}
            <em>
              This is currently limited to mentions of <b>D3</b> in your
              server&apos;s <b>#loot</b> channel but we'll be extending it to
              take a specific keyword argument and channel soon!
            </em>
          </p>
        </div>
        <div id="request" className="command">
          <h5>
            <img src="/logo.png" alt="Bot Icon" /> /request
          </h5>
          <p>
            Check if your team has enough power to beat another team.{' '}
            <em>
              Checks if you and 3 others have more than the total requested
              power level.
            </em>
          </p>
        </div>
        <div className="footer">
          <FaDiscord /> <span>More to come!</span>
        </div>
      </section>
    </StyledContentMain>
  )
}

export default HomeContentMain
