import React from 'react'
import styled from '@emotion/styled/macro'

const StyledMapFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
  z-index: 1000;

  @media (min-width: 768px) {
    margin: 0 60px 10px;
  }
  @media (min-width: 1024px) {
    margin: 0 20px 20px;
  }

  .guild-icon {
    width: ${({ size }) => (size === 'large' ? '80px' : '50px')};
    height: ${({ size }) => (size === 'large' ? '80px' : '50px')};
    border-radius: 50%;
    border: 2px solid var(--border);
    box-shadow: 0 1px 5px var(--box-shadow);
    z-index: 1000;
  }

  .guild-name {
    font-family: 'Press Start 2P', cursive;
    font-size: 0.8rem;
    /* color: var(--medium-blue); */
    color: ${({ theme }) =>
      theme === 'light' ? 'var(--medium-blue)' : 'var(--cream)'};
    /* margin-top: ${({ size }) => (size === 'large' ? '40px' : '0px')}; */
    margin-bottom: 2px;
    /* margin-left: 5px; */
    text-shadow: 0 1px 1px
      ${({ theme }) =>
        theme === 'light' ? 'var(--cream)' : 'var(--star-color)'};
  }

  .username {
    font-family: 'Press Start 2P', cursive;
    font-size: 0.6rem;
    font-weight: 600;
    color: ${({ theme }) =>
      theme === 'light' ? 'var(--star-color)' : 'var(--white)'};
    /* margin-left: 5px; */
    margin-top: 2px;
    text-shadow: 0 1px 1px
      ${({ theme }) =>
        theme === 'light' ? 'var(--cream)' : 'var(--star-color)'};
  }
`

function MapFooter({
  me,
  iconUrl,
  name,
  theme,
  size = 'small',
  onClick = () => {},
}) {
  return (
    <StyledMapFooter
      theme={theme}
      size={size}
      onClick={onClick}
      className="map-footer"
    >
      <img src={iconUrl} alt={name} className="guild-icon" />
      <div>
        <h2 className="guild-name">{name}</h2>
        {me && <h3 className="username">@{me.username}</h3>}
      </div>
    </StyledMapFooter>
  )
}

export default MapFooter
