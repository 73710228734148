import styled from '@emotion/styled/macro'

// NOTE: we use theme = light/dark, it is not the
// same as @emotion's theme context!
const StyledMap = styled.div`
  background: var(--background) url('/map-dark.png') no-repeat center center;
  background-size: cover;
  width: 100%;
  height: calc(100% + 60px);
  @media (min-width: 768px) {
    /* Avoid Mobile Safari Bugs */
    background-attachment: fixed;
  }
`

export default StyledMap
