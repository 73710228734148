import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled/macro'
import { useTransitionContext } from '../contexts/TransitionContext'

const StyledLayout = styled.div`
  height: 100%;
`

function Layout({ children }) {
  const { isTransitioning, setIsTransitioning } = useTransitionContext()
  const [transitionClass, setTransitionClass] = useState('enter')

  useEffect(() => {
    if (isTransitioning) {
      setTransitionClass('exit')
      const timer = setTimeout(() => {
        setTransitionClass('enter')
        setIsTransitioning(false)
      }, 300)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [isTransitioning, setIsTransitioning])

  return (
    <StyledLayout className={`fade-${transitionClass}`}>
      {children}
    </StyledLayout>
  )
}

export default Layout
