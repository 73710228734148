import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import tz_lookup from 'tz-lookup'

function getLocalTime(lat, lon) {
  // Find the timezone of the given lat/lng
  const timeZone = tz_lookup(lat, lon)

  // Convert current UTC time to local time in that timezone
  const localTime = utcToZonedTime(new Date(), timeZone)

  // Format the local time as 12-hour format with AM/PM
  return format(localTime, 'h:mm aaa')
}

export default getLocalTime
