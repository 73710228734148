import styled from '@emotion/styled/macro'

const StyledFooter = styled.footer`
  min-height: 200px;
  padding: 2em 5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  margin-top: -50px;
  gap: 20px;
  background-color: var(--cream);
  background: linear-gradient(180deg, var(--white) 0%, var(--cream) 100%);
  border-top: 1px solid var(--border);
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  box-shadow:
    inset 0px 10px 30px var(--cream),
    0px -5px 10px var(--box-shadow);
  font-family: 'Cinzel', serif;
  font-weight: 600;
  font-size: 0.8rem;
  color: var(--dark-blue);
  a {
    color: var(--dark-blue);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .middle {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      /* top: -55px; */
      /* order: -1; */
      h6 {
        font-weight: 900;
        font-size: 1.5rem;
        color: var(--dark-blue);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-shadow: 0 2px 4px var(--box-shadow);
        z-index: 1;
      }
      .shield-icon {
        width: 100px;
        height: 100px;
        filter: drop-shadow(0 1px 5px var(--box-shadow));
      }
    }
    .copyright {
      flex: 1;
      color: var(--dark-blue);
      order: 1;
      color: var(--box-shadow-heavy);
      a {
        color: var(--box-shadow-heavy);
      }
    }
    .links {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      gap: 5px;
      span {
        display: none;
      }
    }
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      .middle {
        /* order: 0; */
      }
      .copyright {
        order: 0;
        color: var(--dark-blue);
        a {
          color: var(--dark-blue);
        }
      }
      .links {
        order: 0;
        flex-direction: row;
        justify-content: flex-end;
        gap: 5px;
        span {
          display: inline;
        }
        a {
          white-space: nowrap;
          /* border-bottom: 1px solid var(--pink); */
        }
      }
    }
  }
`

export default StyledFooter
