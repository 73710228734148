import { useMemo } from 'react'

// Get's map guildId and userId from either route params or URL params
const useUrlParams = ({ guildId: routeGuildId, userId: routeUserId }) => {
  return useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    if (routeGuildId) {
      const demo = params.get('demo')
      if (routeUserId) {
        return { guildId: routeGuildId, userId: routeUserId, demo }
      }
      return { guildId: routeGuildId, userId: null, demo }
    }
    const guildId = params.get('id')
    const userId = params.get('user')
    return { guildId, userId }
  }, [routeGuildId, routeUserId])
}

export default useUrlParams
