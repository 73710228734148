import React from 'react'
import styled from '@emotion/styled/macro'
import LeaderboardHeader from './LeaderboardHeader'
import LeaderboardItem from './LeaderboardItem'

const StyledLeaderboard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-height: var(--modalHeight);
  font-family: 'Press Start 2P', cursive;
  font-size: 12px;
  color: var(--white);
  /* padding: 20px 10px 0; */
  box-sizing: border-box;
  @media (max-width: 767px) {
    & {
      /* max-height: var(--viewportHeight); */
      height: calc(var(--viewportHeight) - 60px);
      max-height: none;
      font-size: 11px;
    }
  }
`

const StyledContent = styled.div`
  padding: 3px 0 80px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
`

function Leaderboard({ me, members, guild, onUserClick }) {
  return (
    <StyledLeaderboard>
      <LeaderboardHeader iconUrl={guild.iconUrl} name={guild.name} />

      <StyledContent>
        {members.map((member, index) => (
          <LeaderboardItem
            i={index}
            isMe={me && member.user_id === me.user_id}
            key={member.username}
            avatarUrl={member.avatarUrl}
            lastActivity={member.lastActivity}
            leader={member.leader}
            power={member.power}
            viceLeader={member.viceLeader}
            status={member.status}
            username={member.username}
            onUserClick={onUserClick}
          />
        ))}
      </StyledContent>
    </StyledLeaderboard>
  )
}

export default Leaderboard
