export const DARK_BLUE = '#0D2847'
export const TERMINATOR_BORDER = '#FF8A65'
export const TERMINATOR_SHADING = '#133D71'
export const SUN_COLOR = '#FF8A65'
export const MOON_COLOR = '#C0C0C0'
export const STAR_COLOR = '#3DA5A9'
export const WHITE = '#FFFFFF'
export const CREAM = '#F2E1BA'
export const BORDER = '#ffffff77'
export const PINK = 'hotpink'
