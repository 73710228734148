/* LeaderboardHeader.jsx */
import React from 'react'
import { FaShieldAlt } from 'react-icons/fa'
import styled from '@emotion/styled/macro'

const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 10px 0;
  .heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 0 7px;
    margin: 0;
    .title {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      h1 {
        margin: 0;
        padding: 0;
        font-size: 0.8rem;
        color: var(--cream);
      }
      h2 {
        margin: 0;
        padding: 0 2px;
        font-size: 0.6rem;
        color: var(--border);
      }
    }
    .shield-icon {
      width: 40px;
      height: 40px;
      color: var(--sun-color);
    }
    .guild-logo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid var(--border);
      box-shadow: 0 1px 5px var(--box-shadow);
    }
  }
  .column-labels {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 15px;
    padding: 10px 0 5px;
    /* line-height: 20px; */
    color: var(--border);
    .rank {
      min-width: 26px;
      text-align: right;
    }
    .name {
      flex: 1;
      /* padding-left: 38px; */
    }
    .score {
      text-align: right;
    }
  }
  .divider {
    width: 100%;
    height: 2px;
    background: var(--border);
  }
`

function Heading({ iconUrl, name }) {
  return (
    <div className="heading">
      {iconUrl && <img src={iconUrl} alt={name} className="guild-logo" />}
      {!iconUrl && <FaShieldAlt className="shield-icon" />}
      <div className="title">
        <h1>Leaderboard</h1>
        <h2>{name}</h2>
      </div>
    </div>
  )
}

function ColumnLabels() {
  return (
    <div className="column-labels">
      <span className="rank">#</span>
      <span className="name">Player</span>
      <span className="score">Score</span>
    </div>
  )
}

function Divider() {
  return <div className="divider" />
}

function LeaderboardHeader({ iconUrl, name }) {
  return (
    <Header>
      <Heading iconUrl={iconUrl} name={name} />
      <ColumnLabels />
      <Divider />
    </Header>
  )
}

export default LeaderboardHeader
