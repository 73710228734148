import React from 'react'
import styled from '@emotion/styled/macro'

const StyledMapHero = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  min-width: 80%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 15px 0;
  align-items: center;
  color: ${({ theme }) =>
    theme === 'light' ? 'var(--white)' : 'var(--sun-color)'};
  h2 {
    margin: 0 0 0.75rem;
    padding: 0;
    font-family: 'Press Start 2P', cursive;
    font-size: 1.75rem;
    line-height: 3rem;
    letter-spacing: 0.15rem;
    /* text-shadow: -1px 1px 0px var(--sun-color), -2px 2px 1px var(--white),
      -3px 3px 1px var(--star-color), -4px 4px 1px var(--star-color); */
    text-shadow:
      -1px 1px 0 var(--white),
      -2px 2px 0 var(--white),
      -3px 3px 1px var(--white),
      -4px 4px 1px var(--white),
      -5px 5px 1px var(--star-color),
      -6px 6px 10px var(--star-color);
    @media (min-width: 768px) {
      font-size: 1.75rem;
    }
    @media (min-width: 1024px) {
      font-size: 2.5rem;
    }
    @media (min-width: 1400px) {
      font-size: 3rem;
    }
  }
  h3 {
    margin: 0;
    padding: 0;
    font-family: 'Cinzel', 'courier new', courier, monospace;
    line-height: 1.5rem;
    font-size: 1.2rem;
    color: var(--white);
    text-shadow:
      /* 0 1px 5px var(--pink), */
      0px 0px 5px #b393d3,
      0px 0px 10px #b393d3,
      0px 0px 10px #b393d3,
      0px 0px 20px #b393d3;
    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
    @media (min-width: 1400px) {
      font-size: 1.6rem;
    }
  }
`

function MapHero() {
  return (
    <StyledMapHero>
      <h2>Unite, Rank, & Conquer</h2>
      <h3>Your Team's Adventure Begins on the Map!</h3>
    </StyledMapHero>
  )
}

export default MapHero
