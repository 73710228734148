import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import './Modal.css'
import { FaSkullCrossbones } from 'react-icons/fa'

function Modal({ isOpen, onClose, children }) {
  const hasBeenOpened = useRef(false)
  const [animationClass, setAnimationClass] = useState('')

  useEffect(() => {
    if (isOpen) {
      hasBeenOpened.current = true
      setTimeout(() => {
        setAnimationClass('show')
      }, 50)
      document.body.style.overflow = 'hidden'
    } else {
      if (hasBeenOpened.current) {
        setAnimationClass('hide')
      }
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  if (!hasBeenOpened.current) return null
  if (animationClass === '') return null

  return ReactDOM.createPortal(
    <div
      className={`modal-overlay ${animationClass}`}
      onClick={onClose}
      onAnimationEnd={() => {
        if (animationClass === 'hide') setAnimationClass('')
      }}
    >
      <div className="modal-window" onClick={(e) => e.stopPropagation()}>
        <button onClick={onClose} className="modal-close-button">
          <FaSkullCrossbones />
          <span>close</span>
        </button>
        {children}
      </div>
    </div>,
    document.getElementById('portal'),
  )
}

export default Modal
