import { isMobile } from 'react-device-detect'

let API_ENDPOINT = 'https://api.mapwarrior.gg'
let WS_ENDPOINT = 'wss://api.mapwarrior.gg'

if (process.env.NODE_ENV !== 'production') {
  let PORT = 3100
  let LOCALHOST = 'localhost'
  if (isMobile) LOCALHOST = '192.168.86.39' // Change to your own IP address
  API_ENDPOINT = `http://${LOCALHOST}:${PORT}`
  WS_ENDPOINT = `ws://${LOCALHOST}:${PORT}`
}

export { API_ENDPOINT, WS_ENDPOINT }
