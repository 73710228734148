import Header from '../components/Header'
import AppLink from '../components/AppLink'
import './PrivacyPolicy.css'

export default function TermsOfService() {
  return (
    <div className="legal-styles-wrapper">
      <div className="legal-styles">
        <header>
          <Header theme="light" />
        </header>
        <main>
          <h1>Terms of Service</h1>

          <h3>Last Updated: September 9, 2023</h3>

          <h2>Acceptance of Terms</h2>

          <p>
            By using MapWarrior, you agree to and accept these Terms of Service.
            If you don't agree with any of these terms, please discontinue using
            our services.
          </p>

          <h2>Changes to Terms</h2>

          <p>
            We may modify these Terms of Service from time to time. We'll inform
            users of any changes and continue using our services after such
            changes means you accept the updated terms.
          </p>

          <h2>Use of MapWarrior</h2>

          <p>
            <em>You may not:</em>
          </p>
          <ul>
            <li>Use our service for any illegal purposes.</li>
            <li>Resell or exploit any portion of our service.</li>
            <li>Attempt to harm or disrupt the service in any way.</li>
          </ul>

          <h2>User Responsibilities</h2>

          <p>
            You are responsible for maintaining the confidentiality of your
            account and ensuring all activities that occur are compliant with
            these terms.
          </p>

          <h2>Content</h2>

          <p>
            All content provided on MapWarrior is for informational purposes
            only. We make no representations regarding its accuracy or
            completeness.
          </p>

          <h2>Termination</h2>

          <p>
            We reserve the right to terminate or suspend access to our service
            without prior notice or liability, for any reason, including
            violation of these terms.
          </p>

          <h2>Disclaimer</h2>

          <p>
            MapWarrior is provided on an "as is" basis without any warranties of
            any kind, either express or implied.
          </p>

          <h2>Limitation of Liability</h2>

          <p>
            MapWarrior or its representatives will not, under any circumstances,
            be liable for any direct or indirect damages arising from the use or
            inability to use our services.
          </p>

          <h2>Governing Law</h2>

          <p>
            These terms shall be governed by and construed according to the laws
            of the State of Louisiana.
          </p>

          <h2>Contact Us</h2>

          <p>
            For any questions about these Terms of Service, please contact us at{' '}
            <a href="mailto:info@hashtagmagic.io?subject=MapWarrior">
              info@hashtagmagic.io
            </a>
            .
          </p>
        </main>
        <footer>
          <div className="links">
            <AppLink to="/">Home</AppLink>
            <AppLink to="/privacy-policy">Privacy Policy</AppLink>
            <AppLink to="/terms-of-service">Terms of Service</AppLink>
          </div>
        </footer>
      </div>
    </div>
  )
}
