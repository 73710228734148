import { useState, useEffect } from 'react'

const determineTheme = () => {
  const currentHour = new Date().getHours()
  return currentHour >= 6 && currentHour <= 18 ? 'light' : 'dark'
}

const useTheme = () => {
  const [theme, setTheme] = useState(determineTheme())
  const isLightTheme = theme === 'light'

  useEffect(() => {
    const interval = setInterval(() => {
      const newTheme = determineTheme()
      if (theme !== newTheme) {
        setTheme(newTheme)
      }
    }, 60 * 60 * 1000) // Check every hour

    return () => clearInterval(interval) // Cleanup on unmount
  }, [theme])

  return [theme, setTheme, isLightTheme]
}

export default useTheme
