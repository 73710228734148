import { useState, useEffect } from 'react'

const DEBUG = process.env.DEBUG || false

function useOrientation() {
  const [orientation, setOrientation] = useState(getOrientation())

  function getOrientation() {
    if (typeof window !== 'undefined') {
      return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
    }
    return 'portrait'
  }

  useEffect(() => {
    if (DEBUG) console.log('useOrientation mounted')
    function handleResize() {
      setOrientation(getOrientation())
    }

    window.addEventListener('resize', handleResize)

    // Clean up the listener when the component using the hook unmounts
    return () => {
      if (DEBUG) console.log('useOrientation unmounted')
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // DEBUG
  useEffect(() => {
    if (DEBUG)
      console.log(orientation, window.innerWidth, 'x', window.innerHeight)
  }, [orientation])

  return orientation
}

export default useOrientation
