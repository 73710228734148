// import { Response } from 'react-router-dom'
import React, { useEffect } from 'react'

function ExternalRedirect({ url, message }) {
  useEffect(() => {
    window.location.href = url
  }, [url])

  return (
    <div>
      Redirecting you to our external resource...
      <br />
      {message && <span>{message}</span>}
      <br />
      If you are not redirected, <a href={url}>click here</a>.
    </div>
  )
}

export default ExternalRedirect
