import styled from '@emotion/styled/macro'

const StyledList = styled.ol`
  margin-top: 10px;
  /* list-style: number; */
  counter-reset: list-number;
  a {
    color: var(--pink);
    text-decoration: none;
  }
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
    max-width: 600px;
    font-weight: 500;
    color: var(--cream);
    @media (min-width: 768px) {
      font-weight: 600;
    }
    span:before {
      counter-increment: list-number;
      content: counter(list-number);
      /* margin-bottom: 10px; */
      margin-right: 5px;
      width: 40px;
      height: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-family: 'Press Start 2P', cursive;
      font-size: 1rem;
      color: var(--white);
      text-shadow: -1px -1px 0px inset var(--purple);
      border: 1px solid var(--white);
      box-shadow:
        -1px 1px 2px var(--box-shadow-heavy),
        inset 0 5px 5px rgba(255, 255, 255, 0.6);
      background-color: var(--background);
      background-image: linear-gradient(
        to bottom,
        var(--pink) 0%,
        var(--sun-color) 100%
      );
      /* background-image: conic-gradient(
        #553c9a 0%,
        #553c9a 33%,
        #ee4b2b 33%,
        #ee4b2b 66%,
        #00c2cb 66%,
        #00c2cb 99%
      ); */
      /* background-image: repeating-radial-gradient(
        circle closest-side at center,
        #553c9a 0%,
        #ee4b2b 33%,
        #553c9a 33%,
        #ee4b2b 66%,
        #553c9a 66%,
        #ee4b2b 99%
      ); */
      border-radius: 50%;
      /* border-radius: 10px; */
    }
    div {
      display: inline-block;
    }
  }
`

export default StyledList
