import React from 'react'
import styled from '@emotion/styled/macro'

const StyledMapAttribution = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
  z-index: 1000;
  font-size: 0.5rem;

  @media (min-width: 768px) {
    margin: 0 60px 10px;
    font-size: 0.55rem;
  }
  @media (min-width: 1024px) {
    margin: 0 20px 20px;
    font-size: 0.6rem;
  }
`

function MapAttribution({ theme }) {
  return (
    <StyledMapAttribution theme={theme} className="map-footer">
      <div>
        <p>
          {' '}
          Powered by{' '}
          <a
            href="https://openstreetmap.org/copyright"
            target="_blank"
            rel="noreferrer"
          >
            OpenStreetMap
          </a>
        </p>
      </div>
    </StyledMapAttribution>
  )
}

export default MapAttribution
