import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = ({ children }) => {
  const { pathname, hash } = useLocation()
  const scrollingElement = document.scrollingElement || document.documentElement

  useEffect(() => {
    // Save scroll positions in sessionStorage
    const popstateListener = () => {
      sessionStorage.setItem(
        `@@scroll|${pathname}`,
        JSON.stringify({
          scrollX: window.scrollX,
          scrollY: window.scrollY,
        }),
      )
    }

    window.addEventListener('popstate', popstateListener)
    return () => {
      window.removeEventListener('popstate', popstateListener)
    }
  }, [pathname])

  useEffect(() => {
    try {
      const savedScrollPositionString = sessionStorage.getItem(
        `@@scroll|${pathname}`,
      )
      const savedScrollPosition = JSON.parse(savedScrollPositionString || '{}')

      if (hash) {
        // If there is a hash in the URL, try to scroll to the element
        const element = document.querySelector(hash)
        if (element) {
          element.scrollIntoView()
          return
        }
      }

      if (savedScrollPosition.scrollX || savedScrollPosition.scrollY) {
        // Scroll to the saved position if it exists
        window.scrollTo(
          savedScrollPosition.scrollX,
          savedScrollPosition.scrollY,
        )
      } else {
        // Scroll to top if no saved position
        scrollingElement.scrollTop = 0
      }
    } catch (e) {
      // In case of an error, just scroll to top
      scrollingElement.scrollTop = 0
    }
  }, [pathname, hash, scrollingElement])

  return children
}

export default ScrollToTop
