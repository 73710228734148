import styled from '@emotion/styled/macro'
import { keyframes } from '@emotion/react/macro'

const spinAndRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(540deg);
  }
  15% {
    transform: rotate(270deg);
  }
  20% {
    transform: rotate(360deg);
  }
  40% {
    transform: rotateY(360deg);
  }
  60% {
    transform: rotateY(0deg);
  }
  80% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(540deg);
  }
  15% {
    transform: rotate(270deg);
  }
  20% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const flip = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  10% {
    transform: rotateY(540deg);
  }
  15% {
    transform: rotateY(270deg);
  }
  20% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(360deg);
  }
`

const jiggle = keyframes`
  0% {
    transform: rotateY(0deg) scale(1) rotateZ(0);
  }
  4% {
    transform: rotateY(45deg);
  }
  8% {
    transform: rotateY(-45deg);
  }
  12% {
    transform: rotateY(20deg);
  }
  16% {
    transform: rotateY(0deg);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.5) rotateZ(-5deg);
  }
  75% {
    transform: scale(0.9) rotateZ(2deg);
  }
  100% {
    transform: rotateY(0deg) scale(1) rotateZ(0);
  }
`

// const color = keyframes`
//   33% {
//     background-color: var(--purple);
//   }
//   66% {
//     background-color: var(--medium-blue);
//   }
// `

const StyledContentMain = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media (min-width: 768px) {
    gap: 150px;
  }
  h2 {
    font-family: 'Press Start 2P', cursive;
    font-size: 2rem;
    line-height: 2.25rem;
    margin-top: 20px;
    margin-bottom: 20px;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(
      to right,
      var(--purple) 0%,
      var(--star-color) 100%
    );
    @media (min-width: 768px) {
      font-size: 2rem;
      line-height: 2.25rem;
    }
  }
  h4 {
    .icon {
      margin-bottom: -10px;
      color: var(--pink);
    }
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 2.5rem;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    color: var(--white);
    text-shadow:
      -1px 1px 0px #957dad,
      -2px 2px 0px #ee4b2b,
      -3px 3px 0px #00c2cb,
      -4px 4px 0px #ff7f50,
      -5px 5px 0px #553c9a;
  }
  p {
    font-family: 'source-code-pro', Menlo, Monaco, Consolas, 'Courier New';
  }
  .group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 50px;
    /* padding: 0 1em; */
    @media (min-width: 1024px) {
      gap: 100px;
      width: 100%;
      max-width: 1600px;
      flex-direction: row;
      margin: 0 auto;
      justify-content: space-around;
      align-items: flex-end;
    }
  }
  section {
    p {
      font-weight: 600;
      margin-bottom: 10px;
    }
    &.heading {
      /* margin-left: 40px; */
      .live-demo {
        margin-top: 20px;
        margin-left: 10px;
        display: inline-block;
        svg {
          color: var(--pink) !important;
          margin-bottom: -1px;
          transform: rotate(-10deg);
          margin-right: 2px;
          filter: drop-shadow(-1px 1px 0 var(--drop-shadow-heavy));
        }
        a {
          padding: 10px 15px;
          border-radius: 20px;
          background-color: var(--cream);
          box-shadow: -1px 2px 3px var(--box-shadow);
          color: var(--background);
          text-decoration: none;
          font-family: 'Cinzel', serif;
          font-weight: 600;
          &:hover {
            box-shadow: -1px 1px 2px var(--box-shadow);
          }
          &:active {
            box-shadow: 0 0 0 var(--box-shadow);
            border: 1px solid var(--pink);
          }
        }
      }
      @media (min-width: 768px) {
        margin-left: 20px;
      }
    }
    &.description {
      align-self: center;
      width: 100%;
      max-width: 600px;
      padding: 20px 30px;
      background-color: rgba(255, 255, 255, 0.75);
      box-shadow:
        inset 2px 2px 5px var(--box-shadow),
        inset -1px -1px 2px var(--box-shadow);
      border-radius: 100px 10px 100px 10px;
      color: var(--background);
      p {
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        background-image: linear-gradient(
          to bottom right,
          var(--background) 0%,
          var(--pink) 100%
        );
      }
      h4 {
        margin: 0px;
        text-align: right;
        .icon {
          color: var(--background);
          filter: drop-shadow(-2px 2px 0px var(--pink));
        }
        /* @media (min-width: 768px) {
          text-align: left;
        } */
      }
      &.commands {
        max-width: 700px;
        padding: 10px;
        h4 {
          color: var(--medium-blue);
        }
        p {
          padding: 0 10px;
          font-weight: 500;
          font-size: 0.9rem;
          line-height: 1.1rem;
          color: var(--background);
          small {
            font-size: 0.7rem;
          }
        }
        .note {
          padding: 0 5px;
          margin-top: 50px;
          h5 {
            display: flex;
            align-items: baseline;
            gap: 5px;
            font-family: 'Cinzel', serif;
            font-weight: 900;
            font-size: 1rem;
            color: var(--background);
            border-bottom: 1px solid var(--star-color);
            margin-bottom: 10px;
            img {
              width: 30px;
              height: 30px;
              border-radius: 5px;
            }
          }
          p {
            padding: 0;
            /* padding: 0 5px; */
            margin-bottom: 30px;
            span {
              font-family: 'Cinzel', serif;
              font-weight: 900;
              color: var(--background);
              text-decoration: underline;
            }
          }
        }
        .command {
          margin-top: 20px;
          padding: 10px;
          /* border: 1px solid var(--border-dark); */
          border-radius: 30px 30px 60px 10px;
          background-color: var(--border);
          box-shadow: -1px 1px 2px var(--box-shadow);
          h5 {
            display: flex;
            align-items: baseline;
            gap: 5px;
            padding: 5px 20px 5px 5px;
            background-color: var(--discord);
            box-shadow:
              /* -1px 1px 2px var(--box-shadow), */ inset -2px
              2px 5px var(--border-dark);
            border-radius: 30px;
            font-family: 'Press Start 2P', cursive;
            font-size: 1rem;
            margin-bottom: 10px;
            color: var(--white);
            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
            @media (min-width: 768px) {
              font-size: 1.2rem;
            }
          }
          p {
            b {
              font-family: 'Press Start 2P', serif;
              font-size: 0.7rem;
              color: var(--background);
            }
          }
        }
        .footer {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
          padding: 30px 10px 20px;
          span {
            font-style: italic;
          }
          /* perspective: 250px; */
          /* height: 50px; */
          svg {
            /* transform: rotateY(45deg); */
            color: var(--discord);
            font-size: 3rem;
            filter: drop-shadow(0 1px 1px var(--box-shadow));
            /* filter: drop-shadow(-1px 2px 0px var(--pink)); */
          }
          @media (min-width: 768px) {
            justify-content: center;
          }
        }
      }
    }
    &.how-to {
      display: inline-block;
      align-self: center;
      max-width: 600px;
      background-color: #133d71cc;
      padding: 20px 20px 30px;
      border-radius: 10px 80px 10px 80px;
      border: 1px solid var(--dark-blue);
      box-shadow: -1px 1px 5px var(--box-shadow-heavy);
    }
    &.shield {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      /* border-radius: 250px; */
      filter: drop-shadow(0 0 10px var(--dark-blue));
      h4 {
        margin-left: 0px;
        text-align: center;
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        width: 320px;
        height: 320px;
        border-radius: 20px;
        overflow: hidden;
        .icon {
          filter: drop-shadow(0 0 10px var(--dark-blue));
          /* width: 200px; */
          width: 100%;
          height: auto;
          background-size: contain;
          animation-delay: 3s;
          animation: ${spinAndRotate} 7s cubic-bezier(0.36, 0.07, 0.19, 0.97)
            infinite;
        }
        .img {
          width: 100%;
          margin: 0 auto;
          height: auto;
          background-size: cover;
          /* border: 10px solid var(--dark-blue); */
          border-radius: 40px;
        }
        .spin {
          animation-delay: 3s;
          animation: ${spin} 7s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
        }
        .spinAndRotate {
          animation-delay: 3s;
          animation: ${spinAndRotate} 7s cubic-bezier(0.36, 0.07, 0.19, 0.97)
            infinite;
        }
        .flip {
          animation-delay: 3s;
          animation: ${flip} 7s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
        }
        .rotate {
          animation-delay: 3s;
          animation: ${jiggle} 7s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
          /* animation: ${jiggle} 7s ease-in-out infinite; */
        }
      }
    }
  }
`

export default StyledContentMain

// flex: 1;
// display: flex;
// flex-direction: column;
// justify-content: flex-start;
// align-items: stretch;
// gap: 100px;
// padding: 1em;
// @media (min-width: 768px) {
//   gap: 100px;
// }
// section {
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: stretch;
//   gap: 10px;
//   @media (min-width: 768px) {
//     max-width: 50%;
//     /* &:nth-of-type(even) {
//       align-self: flex-end;
//       max-width: 400px;
//     } */
//   }
// }

// /* background-image: url('https://mapwarrior.gg/logo.png'); */
// /* background-size: 50px; */
// /* background-repeat: repeat; */
// /* background-position: center; */
// /* background-image: conic-gradient(
//   #553c9a 0%,
//   #553c9a 10%,
//   #ee4b2b 10%,
//   #ee4b2b 20%,
//   #00c2cb 20%,
//   #00c2cb 30%
// ); */
