import React from 'react'
import styled from '@emotion/styled/macro'
// import { FaShieldAlt } from 'react-icons/fa'
import { ReactComponent as ShieldIcon } from '../../../assets/shield-icon.svg'
import AppLink from '../../../components/AppLink'

const StyledMapHeader = styled.div`
  position: absolute;
  z-index: 900;
  top: 0;
  right: 50px;
  left: 50px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) =>
    theme === 'light' ? 'var(--white)' : 'var(--sun-color)'};
  font-size: 3rem;
  padding: 15px 0;
  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-family: 'Cinzel', sans-serif;
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: 900;
    color: ${({ theme }) =>
      theme === 'light' ? 'var(--medium-blue)' : 'var(--white)'};
    margin: 0;
    margin-left: 1px; /* This puts the "A" right in the center */
    padding: 0;
    border-radius: 30px;
    text-align: center;
    text-shadow: 0 2px 4px var(--box-shadow);
    transition: all 0.2s ease-in-out;
  }
  .icon {
    width: 60px;
    height: 60px;
    /* filter: drop-shadow(0 1px 5px var(--box-shadow)); */
    filter: ${({ theme }) =>
      theme === 'light'
        ? 'drop-shadow(0 1px 5px var(--box-shadow))'
        : 'drop-shadow(0 1px 5px var(--dark-blue))'};
    transition: all 0.2s ease-in-out;
  }
`

function MapHeader({ theme }) {
  return (
    <StyledMapHeader theme={theme}>
      <AppLink to="/">
        <ShieldIcon className="icon" />
        <h1>MapWarrior</h1>
      </AppLink>
    </StyledMapHeader>
  )
}

export default MapHeader
