import React from 'react'
import ReactDOMServer from 'react-dom/server'
import L from 'leaflet'
import SunCalc from 'suncalc'
import { FaMoon, FaShieldAlt } from 'react-icons/fa'
// import styled from '@emotion/styled/macro'
// import { ReactComponent as ShieldIcon } from '../assets/shield-icon.svg'
import { SUN_COLOR, MOON_COLOR } from '../../../styles/Colors'

const ICON_SIZE = 40

export function iconToDataURI(IconComponent, size = ICON_SIZE, color = '#000') {
  const svgString = ReactDOMServer.renderToStaticMarkup(
    <IconComponent size={size} color={color} />,
  )
  const svgBase64 = btoa(svgString)
  return `data:image/svg+xml;base64,${svgBase64}`
}

const lightIcon = new L.Icon({
  iconUrl: iconToDataURI(FaShieldAlt, ICON_SIZE, SUN_COLOR),
  iconSize: [ICON_SIZE, ICON_SIZE],
  iconAnchor: [10, 10], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -10], // point from which the popup should open relative to the iconAnchor
})

const darkIcon = new L.Icon({
  iconUrl: iconToDataURI(FaMoon, ICON_SIZE, MOON_COLOR),
  iconSize: [ICON_SIZE, ICON_SIZE],
  iconAnchor: [10, 10],
  popupAnchor: [0, -10],
})

function getMemberIcon(position) {
  const times = SunCalc.getTimes(new Date(), position[0], position[1])
  const now = new Date()

  if (now >= times.sunrise && now <= times.sunset) {
    return lightIcon
  }
  return darkIcon
}

const renderToString = ({
  theme,
  avatarUrl,
  iconUrl,
  rank,
  status,
  username,
}) =>
  ReactDOMServer.renderToStaticMarkup(
    <div className={`map-marker-icon ${status} ${theme}`}>
      <div className="image">
        {!!avatarUrl && <img src={avatarUrl} alt="Avatar" className="avatar" />}
        {!avatarUrl && <img src={iconUrl} alt="Icon Avatar" className="icon" />}
      </div>
      <div className="label">
        #{rank} {username}
      </div>
    </div>,
  )

function createIcon({ i, theme, avatarUrl, position, status, username }) {
  const icon = getMemberIcon(position)
  const iconUrl =
    icon === lightIcon
      ? iconToDataURI(FaShieldAlt, ICON_SIZE, SUN_COLOR)
      : iconToDataURI(FaMoon, ICON_SIZE - 2, MOON_COLOR)
  const rank = i + 1

  return L.divIcon({
    className: 'map-marker-icon-wrapper', // Don't remove this - it prevents default styles!
    html: renderToString({ theme, avatarUrl, iconUrl, rank, status, username }),
    iconSize: [100, 40],
    iconAnchor: [50, 35],
    popupAnchor: [0, !!avatarUrl ? -15 : -20],
  })
}

export default createIcon

// Doesn't work without renderToString from @emotion/server
// Can't run that on CRA without polyfills and/or ejecting
// const StyledMarpMarkerIcon = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 5px;
//   text-align: center;
//   width: 100px;

//   .image {
//     position: relative;
//     display: inline-block;
//     width: 40px;

//     img.avatar {
//       width: 40px;
//       height: 40px;
//       border-radius: 50%;
//       border: 1px solid var(--border);
//       /* box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5); */
//     }

//     img.icon {
//       width: unset;
//       height: unset;
//       border: none;
//       box-shadow: none;
//       border-radius: 0;
//       filter: drop-shadow(0 1px 0 rgba(255, 255, 255, 0.5));
//     }
//   }

//   &.online .image::before {
//     content: '';
//     display: block;
//     position: absolute;
//     top: -2px;
//     right: -2px;
//     width: 14px;
//     height: 14px;
//     background-color: var(--green);
//     border-radius: 50%;
//     border: 1px solid var(--white);
//     z-index: 1000;
//   }

//   .label {
//     padding: 0 10px;
//     color: var(--white);
//     font-size: 12px;
//     font-weight: 700;
//     text-align: center;
//     white-space: nowrap;
//     background-color: var(--medium-blue);
//     border-radius: 10px;
//     border: none;
//     /* border: 1px solid var(--border); */
//     box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
//   }

//   &.online .label {
//     background-color: var(--green);
//   }
// `
