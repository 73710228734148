import { createContext, useContext, useState } from 'react'

// Create a context for the route transition
const TransitionContext = createContext()

export const useTransitionContext = () => useContext(TransitionContext)

export const TransitionProvider = ({ children }) => {
  const [isTransitioning, setIsTransitioning] = useState(false)

  return (
    <TransitionContext.Provider value={{ isTransitioning, setIsTransitioning }}>
      {children}
    </TransitionContext.Provider>
  )
}
