import React, { useState } from 'react'
import { useMapEvents } from 'react-leaflet'
import {
  FaMoon,
  FaSun,
  FaGlobe,
  FaSkullCrossbones,
  FaTrophy,
  FaDiscord,
} from 'react-icons/fa'
import styled from '@emotion/styled/macro'
import { PINK, SUN_COLOR, TERMINATOR_SHADING, WHITE } from '../../styles/Colors'
import { DEFAULT_CENTER } from './TeamMap'
// import GradientIcon from './GradientIcon'
import './MapControls.css'

const StyledControls = styled.div`
  position: absolute;
  right: 14px;
  top: 14px;
  /* top: 85px; */
  display: flex;
  flex-direction: column;
  z-index: 1000;
  box-shadow: 0 1px 5px var(--box-shadow);
  border: 2px solid var(--border);
  border-radius: 20px;

  @media (max-width: 767px) {
    right: 10px;
    top: 10px;
  }

  & > button + & > button {
    border-top: none;
  }

  & > button {
    font-size: 14px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
    margin: 0;
    box-shadow: none;
    border: none;
    /* border-radius: 4px; */
    border-bottom: 1px solid
      ${({ theme }) =>
        theme === 'light' ? 'var(--border-dark)' : 'var(--border)'};
    background-color: ${({ theme }) =>
      theme === 'light' ? 'var(--white)' : 'var(--medium-blue)'};
    cursor: pointer;
    text-align: center;
    z-index: 1000;

    &:first-of-type {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    &:last-of-type {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      border-bottom: none;
    }
  }
`

function MapControls({
  mapRef,
  demo,
  setTheme,
  theme,
  showLeaderboard,
  toggleLeaderboard,
  guildId,
  defaultZoom,
  // orientation,
}) {
  const [zoomLevel, setZoomLevel] = useState(defaultZoom)

  const map = useMapEvents({
    click: (e) => {
      if (map._popup) {
        console.log('click', map._popup)
        map._popup = null
        return
      }
      const excludedClasses = [
        'map-footer',
        'map-warrior-controls',
        'guild-icon',
        'map-warrior-header',
      ]
      // Check if the event's target or its parents have any of the excluded classes
      let target = e.originalEvent.target
      while (target != null) {
        if (target.classList) {
          for (const className of excludedClasses) {
            if (target.classList.contains(className)) {
              return // Exit the function early without flying to clicked location
            }
          }
        }
        target = target.parentElement
      }
      map.flyTo(e.latlng) // Go to clicked location if not prevented
    },
    zoomend: () => {
      setZoomLevel(map.getZoom()) // Track zoom level
    },
  })

  // useEffect(() => {
  //   console.log('useEffect orientation', orientation)
  //   if (zoomLevel !== defaultZoom) {
  //     mapRef.flyTo([20, 0], defaultZoom);
  //   }
  // }, [orientation])

  function resetView() {
    if (mapRef) {
      mapRef.flyTo(DEFAULT_CENTER, defaultZoom)
    }
  }

  function toggleTheme() {
    setTheme((prev) => (prev === 'light' ? 'dark' : 'light'))
  }

  function goToDiscord() {
    console.log('guildId', guildId)
    if (!guildId) return
    if (demo) {
      window.open('https://discord.com/invite/QUdZQFyATF', '_blank')
      return
    }
    // window.open('https://discord.gg/Yq4bCZzj', '_blank'); // Invite URL (public)
    window.open(`https://discord.com/channels/${guildId}`, '_blank') // Direct URL (members only)
  }

  const baseColor = theme === 'light' ? TERMINATOR_SHADING : WHITE

  const themeIcon =
    theme === 'light' ? (
      <FaMoon color={baseColor} />
    ) : (
      <FaSun color={SUN_COLOR} />
    )

  const leaderboardIcon = showLeaderboard ? (
    <FaSkullCrossbones color={PINK} />
  ) : (
    <FaTrophy color={baseColor} />
  )

  return (
    <StyledControls theme={theme} className="map-warrior-controls">
      <button onClick={resetView} title="Reset Map">
        <FaGlobe color={zoomLevel === defaultZoom ? baseColor : SUN_COLOR} />
      </button>
      <button onClick={toggleTheme} title="Toggle Theme">
        {themeIcon}
      </button>
      <button onClick={goToDiscord} title="Go to Discord">
        <FaDiscord color={baseColor} />
      </button>
      <button onClick={toggleLeaderboard} title="View Leaderboard">
        {leaderboardIcon}
      </button>
    </StyledControls>
  )
}

export default MapControls

// const leaderboardIcon = showLeaderboard ? FaSkullCrossbones : FaTrophy
// <button onClick={toggleLeaderboard} title="View Leaderboard">
//   <GradientIcon IconComponent={leaderboardIcon} size={20} />
// </button>
