import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { MapContainer, TileLayer } from 'react-leaflet'
import styled from '@emotion/styled/macro'
import { API_ENDPOINT } from '../../config'
import useOrientation from '../../hooks/useOrientation'
import useUrlParams from '../../hooks/useUrlParams'
import useTheme from '../../hooks/useTheme'
import Loading from '../../components/Loading/Loading'
import Invalid from '../../components/Invalid/Invalid'
import Modal from '../../Modal'
import useDefaultZoom from './hooks/useDefaultZoom'
import useWebSocket from './hooks/useWebSocket'
import getTileUrl from './lib/getTileUrl'
import Leaderboard from './components/Leaderboard'
import MapHeader from './components/MapHeader'
import MapFooter from './components/MapFooter'
import MapMarker from './MapMarker'
import MapControls from './MapControls'
import TerminatorOverlay from './hooks/TerminatorOverlay'
import './TeamMap.css'
import MapAttribution from './components/MapAttribution'

// export const DEFAULT_ZOOM = 2;
export const MIN_ZOOM = 1
export const MIN_ZOOM_PORTRAIT = 0
export const MAX_ZOOM = 12 // Max is 18, but we don't need that much detail
export const DEFAULT_CENTER = [40, 0]

// NOTE: we use theme = light/dark, it is not the
// same as @emotion's theme context!
const StyledMap = styled.div`
  position: relative;
  background-color: var(--dark-blue);

  .map-container {
    width: 100%;
    height: var(--viewportHeight);
    background-color: ${({ theme }) =>
      theme === 'light' ? '#d5d5da' : 'var(--background)'};
  }
`

function TeamMap() {
  const orientation = useOrientation()
  const defaultZoom = useDefaultZoom()
  const { guildId, userId, demo } = useUrlParams(useParams())
  const [theme, setTheme] = useTheme()

  const tileUrl = useMemo(() => getTileUrl(theme), [theme])

  const [mapRef, setMapRef] = useState(null)
  const [guild, setGuild] = useState()
  const [invalid, setInvalid] = useState(false)
  const [members, setMembers] = useState([])
  const [guildLoading, setGuildLoading] = useState(true)
  const [membersLoading, setMembersLoading] = useState(true)
  const [showLeaderboard, setShowLeaderboard] = useState(false)
  const [me, setMe] = useState(null)

  function toggleLeaderboard() {
    setShowLeaderboard((prevVisibility) => !prevVisibility)
  }

  const handleMessage = useCallback((message) => {
    console.log('ws://message', message)
    if (message.type === 'STATUS_UPDATE') {
      const { userId, status, lastActivity } = message.payload
      setMembers((prevMembers) => {
        const updatedMembers = prevMembers.map((member) => {
          if (member.user_id === userId) {
            return {
              ...member,
              status,
              lastActivity: lastActivity || member.lastActivity,
            }
          }
          return member
        })
        return updatedMembers
      })
    }
  }, [])

  useWebSocket(guildId, userId, handleMessage)

  const fetchGuild = useCallback(async (guildId) => {
    try {
      const response = await fetch(`${API_ENDPOINT}/guild/${guildId}`)
      const data = await response.json()
      setGuild(data)
      setGuildLoading(false)
    } catch (error) {
      console.error('Failed to fetch guild:', error)
      setGuildLoading(false)
    }
  }, [])

  const fetchMembers = useCallback(async (guildId) => {
    try {
      const response = await fetch(`${API_ENDPOINT}/members/${guildId}`)
      const data = await response.json()
      const sortedMembers = [...data].sort((a, b) => b.power - a.power)
      setMembers(sortedMembers)
      setMembersLoading(false)
    } catch (error) {
      console.log('Failed to fetch members:', error)
      setMembersLoading(false)
    }
  }, [])

  useEffect(() => {
    if (guildId) {
      fetchGuild(guildId)
      fetchMembers(guildId)
    } else {
      console.log('invalid', guildId)
      setInvalid(true)
      setGuildLoading(false)
      setMembersLoading(false)
    }
  }, [guildId, fetchGuild, fetchMembers])

  useEffect(() => {
    if (!members || !userId) return
    // if (demo) return
    if (demo) {
      // console.log('DEMO MODE')
    }
    const me = members.find((member) => member.user_id === userId)
    setMe(me)
  }, [members, userId, demo])

  const handleClusterClick = (cluster) => {
    // const { lat, lng } = cluster.layer.getLatLng();
    // const zoom = cluster.layer._zoom;
    // console.log('lat', lat, 'lng', lng, 'zoom', zoom)
    // console.log('cluster', cluster.layer)
    // if (mapRef) {
    //   // mapRef.flyTo([lat, lng], cluster.layer._zoom - 1); // Adjust zoom level accordingly
    //   // cluster.layer.spiderfy(); // Make sure the cluster expands
    // }
  }

  const handleUserClick = (index) => {
    if (!members[index]) return
    // console.log('selectedMember', selectedMember)
    // console.log('mapRef', Object.keys(mapRef))
    setShowLeaderboard(false)
    if (mapRef) {
      mapRef.flyTo(members[index].position, 6)
    }
  }

  const handleFooterClick = (e) => {
    if (e.preventDefault) e.preventDefault()
    if (mapRef) {
      mapRef.flyTo(me.position, 6)
    }
  }

  // const showLoading = membersLoading || guildLoading || !guild
  const showLoading = useMemo(
    () => membersLoading || guildLoading || !guild,
    [membersLoading, guildLoading, guild],
  )

  // Don't render the map until we know the zoom level
  // since it must be set on initial render
  if (defaultZoom === null) return

  return (
    <>
      <StyledMap theme={theme} className={theme}>
        <MapHeader theme={theme} />

        <Modal
          isOpen={showLeaderboard}
          onClose={() => setShowLeaderboard(false)}
        >
          <Leaderboard
            me={me}
            members={members}
            guild={guild}
            onUserClick={handleUserClick}
          />
        </Modal>

        <MapContainer
          whenReady={(map) => setMapRef(map.target)}
          maxBounds={[
            [-90, -180], // South-West coordinates
            [90, 180], // North-East coordinates
          ]}
          center={DEFAULT_CENTER}
          zoom={defaultZoom}
          maxZoom={MAX_ZOOM}
          minZoom={orientation === 'portrait' ? MIN_ZOOM_PORTRAIT : MIN_ZOOM}
          maxBoundsViscosity={0.9}
          attributionControl={false}
          className="map-container"
        >
          {showLoading && !invalid && <Loading />}
          {showLoading && invalid && <Invalid />}

          <TileLayer url={tileUrl} />

          {!showLoading && !invalid && (
            <>
              <MapControls
                mapRef={mapRef}
                demo={demo}
                setTheme={setTheme}
                theme={theme}
                showLeaderboard={showLeaderboard}
                toggleLeaderboard={toggleLeaderboard}
                guildId={guild.id}
                orientation={orientation}
                defaultZoom={defaultZoom}
              />

              {guild.iconUrl && (
                <MapFooter
                  me={me}
                  name={guild.name}
                  iconUrl={guild.iconUrl}
                  theme={theme}
                  onClick={handleFooterClick}
                />
              )}

              <MapAttribution theme={theme} />

              <MarkerClusterGroup
                chunkedLoading
                onClick={handleClusterClick}
                maxClusterRadius={50}
              >
                {members.map((member, index) => (
                  <MapMarker
                    key={member.username}
                    i={index}
                    theme={theme}
                    avatarUrl={member.avatarUrl}
                    position={member.position}
                    location={member.location}
                    power={member.power}
                    status={member.status}
                    username={member.username}
                  />
                ))}
              </MarkerClusterGroup>
              <TerminatorOverlay theme={theme} />
            </>
          )}
        </MapContainer>
      </StyledMap>
    </>
  )
}

export default TeamMap
