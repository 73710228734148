import { useEffect, useRef } from 'react'
import { useMap } from 'react-leaflet'
import terminator from '@joergdietrich/leaflet.terminator'
import {
  SUN_COLOR,
  TERMINATOR_BORDER,
  TERMINATOR_SHADING,
} from '../../../styles/Colors'

function TerminatorOverlay({ theme }) {
  const map = useMap()
  const terminatorRef = useRef(null)
  useEffect(() => {
    const isLightTheme = theme === 'light'
    const REFRESH_INTERVAL = 1000 * 60 // 1 minute
    const options = {
      weight: 1, // border width
      color: TERMINATOR_BORDER, // border color
      opacity: isLightTheme ? 1 : 0.5, // border opacity
      fillColor: isLightTheme ? SUN_COLOR : TERMINATOR_SHADING, // shading
      fillOpacity: isLightTheme ? 0.1 : 0.3, // shading opacity
      resolution: 2, // resolution of the terminator (720 * resolution = number of points)
      // lineCap: 'round',
      // lineJoin: 'round',
      // dashArray: '10, 10',
      // dashOffset: '0',
    }
    const term = terminator(options).addTo(map)
    terminatorRef.current = term
    const intervalId = setInterval(() => {
      if (terminatorRef.current) {
        map.removeLayer(terminatorRef.current)
      }
      terminatorRef.current = terminator(options).addTo(map)
    }, REFRESH_INTERVAL)

    return () => {
      clearInterval(intervalId)
      if (terminatorRef.current) {
        map.removeLayer(terminatorRef.current) // Cleanup: Remove the terminator layer on unmount
      }
    }
  }, [map, theme])

  return null
}

export default TerminatorOverlay
