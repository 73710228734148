import { useEffect } from 'react'
import { WS_ENDPOINT } from '../../../config'

const useWebSocket = (guildId, userId, onMessage) => {
  useEffect(() => {
    const ws = new WebSocket(`${WS_ENDPOINT}?guild=${guildId}&user=${userId}`)

    ws.onopen = () => console.log('ws://connected')
    ws.onmessage = (event) => {
      const message = JSON.parse(event.data)
      onMessage(message)
    }
    ws.onclose = () => console.log('ws://disconnected')

    // Handle cleanup
    return () => ws.close()
  }, [guildId, userId, onMessage]) // Only re-run effect if guildId, userId, or onMessage changes
}

export default useWebSocket
