import { Link, useNavigate } from 'react-router-dom'
import { useTransitionContext } from '../contexts/TransitionContext'

const AppLink = ({ to, href, children, ...rest }) => {
  let navigate = useNavigate()
  const { isTransitioning, setIsTransitioning } = useTransitionContext()

  const handleClick = (e) => {
    e.preventDefault()

    if (href) {
      window.open(href, '_blank')
      return
    }

    if (!isTransitioning) {
      setIsTransitioning(true)

      setTimeout(() => {
        navigate(to)
      }, 300) // This matches the CSS transition duration
    }
  }

  return (
    <Link onClick={handleClick} {...rest}>
      {children}
    </Link>
  )
}

export default AppLink
